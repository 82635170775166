import { useEffect } from 'react';
import type { AppProps } from 'next/app';
import { Router, useRouter } from 'next/router';
import Head from 'next/head';
import { appWithTranslation } from 'next-i18next';
import NProgress from 'nprogress';
import { Toaster } from 'react-hot-toast';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { queryClient } from '@/api/queryClient';
import DefaultSeo from '@/components/headers/seo/DefaultSeo';
import SocialJsonLd from '@/components/headers/seo/SocialJsonLd';
import Layout from '@/components/layout';
import OfflineState from '@/components/common/OfflineState';
import usePixels from '@/src/hooks/usePixels';
import ErrorBoundary from '@/components/error/ErrorBoundary';
import { i18n } from 'next-i18next';
import { DEFAULT_LOCALE } from '../constants/i18n';
import '../styles/globals.css';
import '../../datadog.config';
import CookieConsent from '@/components/common/CookieConsent';

/**
 * Ng Progress
 */
const handleRouteStart = () => NProgress.start();
const handleRouteDone = () => NProgress.done();
Router.events.on('routeChangeStart', handleRouteStart);
Router.events.on('routeChangeComplete', handleRouteDone);
Router.events.on('routeChangeError', handleRouteDone);

function App({ Component, pageProps }: AppProps<LayoutProps>) {
  const { initGTMWithConsent, initGtag } = usePixels();
  const { locale = DEFAULT_LOCALE } = useRouter();

  useEffect(() => {
    initGTMWithConsent();
    initGtag();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (i18n) {
      document.documentElement.dir = i18n.dir(locale);
    }
  }, [locale]);

  return (
    <QueryClientProvider client={queryClient}>
      <Head>
        <meta name='viewport' content='minimum-scale=1, initial-scale=1, width=device-width' />
      </Head>
      <ErrorBoundary>
        <SocialJsonLd />
        <DefaultSeo />
        <Toaster position='top-center' />
        <Layout {...pageProps}>
          <Component {...pageProps} />
        </Layout>
        {/* Offline notice */}
        <OfflineState />
        {/* GTM Consent */}
        <CookieConsent />
      </ErrorBoundary>
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
}

export default appWithTranslation(App);

export const SOCIAL_MEDIA = [
  // {
  //   name: 'discord',
  //   en: 'https://www.discord.com',
  //   ar: 'https://www.discord.com',
  //   icon: '/icons/social/discord.svg',
  // },
  // {
  //   name: 'twitch',
  // fr : 'https://www.twitch.com',
  //   en: 'https://www.twitch.com',
  //   ar: 'https://www.twitch.com',
  //   icon: '/icons/social/twitch.svg',
  // },
  {
    name: 'twitter',
    en: 'https://twitter.com/webook',
    ar: 'https://twitter.com/webook',
    icon: '/icons/social/twitter.svg',
  },
  {
    name: 'instagram',
    en: 'https://www.instagram.com/webookfun',
    ar: 'https://www.instagram.com/webookfun',
    icon: '/icons/social/instagram.svg',
  },
  // {
  //   name: 'youtube',
  //   en: 'https://www.youtube.com/c/webookfun',
  //   ar: 'https://www.youtube.com/c/webookfun',
  //   icon: '/icons/social/youtube.svg',
  // },
  {
    name: 'facebook',
    en: 'https://www.facebook.com/webookfun',
    ar: 'https://www.facebook.com/webookfun',
    icon: '/icons/social/fb.svg',
  },
  // {
  //   name: 'snapchat',
  //   en: 'https://www.snapchat.com/add/webookfun',
  //   ar: 'https://www.snapchat.com/add/webookfun',
  //   icon: '/icons/social/snapchat.svg',
  // },
];

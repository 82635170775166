import * as Sentry from '@sentry/nextjs';
import type { NextPageContext } from 'next';
import { TrackContext, ErrorDialogProps } from './types';

export const sentryCaptureException = (exception: Error, context?: TrackContext) => {
  return Sentry.captureException(exception, context);
};

export const sentryCaptureMessage = (message: string, context?: TrackContext) => {
  return Sentry.captureMessage(message, context);
};

export const sentrySetUser = (user: Sentry.User) => {
  return Sentry.setUser(user);
};

export const sentryShowReportDialog = (options: ErrorDialogProps) => {
  return Sentry.showReportDialog(options);
};

export const sentryLastEventId = () => {
  return Sentry.lastEventId();
};

export const sentryUnderscoreException = async (context: NextPageContext) => {
  return Sentry.captureUnderscoreErrorException(context);
};

import CircleSpinner from '@/components/ui/spinners/CircleSpinner';
import React, { forwardRef, useMemo } from 'react';
import { twMerge } from 'tailwind-merge';

type ButtonTheme =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'error'
  | 'success'
  | 'white'
  | 'transparent';
type ButtonShape = 'solid' | 'outlined' | 'text';
type ComponentProps<C extends React.ElementType> = {
  theme?: ButtonTheme;
  shape?: ButtonShape;
  loading?: boolean;
  disabled?: boolean;
  rounded?: boolean;
  arrow?: boolean;
  shiny?: boolean;
  queueProtected?: boolean;
  as?: C | React.ElementType<C>;
};
type Props<C extends React.ElementType = 'button'> = ComponentProps<C> &
  React.ComponentPropsWithoutRef<C>;

// CSS
const SOLID: Record<ButtonTheme, string> = {
  primary:
    'bg-primary hover:bg-primary-light active:bg-primary-dark ring-primary text-primary-contrast disabled:bg-gray-100',
  secondary:
    'bg-secondary hover:bg-secondary-light active:bg-secondary-dark ring-secondary text-secondary-contrast disabled:bg-gray-100',
  tertiary:
    'bg-tertiary hover:bg-tertiary-light active:bg-tertiary-dark ring-tertiary text-tertiary-contrast disabled:bg-gray-100',
  error:
    'bg-error hover:bg-error-light active:bg-error-dark ring-error text-error-contrast disabled:bg-gray-100',
  success:
    'bg-success hover:bg-success-light active:bg-success-dark ring-success text-success-contrast disabled:bg-gray-100',
  white: 'bg-white hover:bg-white active:bg-white-dark ring-white text-black',
  transparent: 'bg-transparent ring-transparent text-black',
};
const OUTLINED: Record<ButtonTheme, string> = {
  primary:
    'border-primary hover:bg-primary-light/5 active:bg-primary-light/10 ring-primary text-primary disabled:border-gray-300',
  secondary:
    'border-secondary hover:bg-secondary-light/5 active:bg-secondary-light/10 ring-secondary text-secondary disabled:border-gray-300',
  tertiary:
    'border-tertiary hover:bg-tertiary-light/5 active:bg-tertiary-light/10 ring-tertiary text-tertiary-contrast disabled:border-gray-300',
  error:
    'border-error hover:bg-error-light/5 active:bg-error-light/10 ring-error text-error-dark disabled:border-gray-300',
  success:
    'border-success hover:bg-success-light/5 active:bg-success-light/10 ring-success text-success-dark disabled:border-gray-300',
  white:
    'border-white hover:bg-white/5 active:bg-white/10 ring-white text-white disabled:border-gray-300',
  transparent: 'border-transparent ring-transparent text-black',
};
const TEXT: Record<ButtonTheme, string> = {
  primary: 'hover:bg-primary-light/5 active:bg-primary-light/5 ring-primary text-primary',
  secondary: 'hover:bg-secondary-light/5 active:bg-secondary-light/5 ring-secondary text-secondary',
  tertiary: 'hover:bg-tertiary-light/5 active:bg-tertiary-light/5 ring-tertiary text-tertiary',
  error: 'hover:bg-error-light/5 active:bg-error-light/5 ring-error text-error-dark',
  success: 'hover:bg-success-light/5 active:bg-success-light/5 ring-success text-success-dark',
  white: 'hover:bg-white/5 active:bg-white/5 ring-white text-white',
  transparent: 'ring-text text-text disabled:bg-transparent',
};

function Button<C extends React.ElementType>(
  {
    children,
    as,
    theme = 'primary',
    shape = 'solid',
    loading,
    disabled,
    className,
    arrow,
    rounded = true,
    shiny,
    queueProtected,
    ...props
  }: Props<C>,
  ref: React.ForwardedRef<never>
) {
  const isQueue = process.env.NEXT_PUBLIC_QUEUE === 'true' && queueProtected;
  const Component = (disabled ? 'button' : isQueue ? 'a' : as || 'button') as React.ElementType;

  const btnClasses = useMemo(() => {
    switch (shape) {
      case 'outlined':
        return OUTLINED[theme] + ' border focus:ring-1';
      case 'text':
        return TEXT[theme] + ' focus:ring-1';
      default:
        return SOLID[theme] + ' focus:ring-1 ring-offset-2';
    }
  }, [theme, shape]);

  return (
    <Component
      className={twMerge(
        btnClasses,
        'relative inline-flex items-center justify-center gap-1 overflow-hidden px-4 py-2 ring-offset-body',
        'text-center transition focus:outline-none disabled:cursor-not-allowed disabled:text-gray-400',
        rounded ? 'rounded-lg' : '',
        className
      )}
      disabled={loading || disabled}
      {...props}
      ref={ref}
    >
      {shiny && (
        <div className='absolute inset-0 h-14 animate-shiny-rippon rtl:animate-shiny-rippon-rtl'>
          <div className='absolute inset-0 h-14 -rotate-45 bg-gradient-to-b from-white/10 via-white/50 to-white/10' />
        </div>
      )}
      {loading && <CircleSpinner />} {children}
      {arrow && (
        <svg width='21' height='21' className='mt-px rtl:rotate-180'>
          <rect
            x='5.16339'
            y='11.7896'
            width='1.667'
            height='11.669'
            rx='0.8335'
            transform='rotate(-90 5.16339 11.7896)'
            fill='currentColor'
          />
          <path
            d='M10.4085 15.3677C10.083 15.6932 10.083 16.221 10.4085 16.5465C10.734 16.872 11.2618 16.872 11.5873 16.5465L16.5883 11.5455C16.9038 11.2299 16.9149 10.7218 16.6133 10.3929L12.0291 5.39187C11.718 5.05254 11.1908 5.02962 10.8514 5.34067C10.5121 5.65173 10.4892 6.17897 10.8002 6.51831L14.8452 10.931L10.4085 15.3677Z'
            fill='currentColor'
          />
        </svg>
      )}
    </Component>
  );
}

const MainButton = forwardRef(Button) as <C extends React.ElementType = 'button'>(
  props: Props<C> & { ref?: React.RefObject<React.ComponentRef<C>> }
) => ReturnType<typeof Button>;

export default MainButton;

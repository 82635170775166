import { SOCIAL_MEDIA } from '@/src/constants/social';
import { SocialProfileJsonLd } from 'next-seo';

export default function SocialJsonLd() {
  return (
    <SocialProfileJsonLd
      type='Organization'
      name='webook.com'
      url='https://webook.com'
      sameAs={SOCIAL_MEDIA.map((link) => link.en)}
    />
  );
}

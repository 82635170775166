import Copyright from './Copyright';

const MainFooter = () => {
  return (
    <footer className='p-6'>
      {/* <div className='container flex flex-col items-center justify-between gap-5 border-t border-white/20 py-8 md:flex-row'>
        <ul className='flex items-center justify-center gap-4'>
          {SOCIAL_MEDIA.map((link) => (
            <li
              key={link.en}
              className='inline-flex rounded-lg border border-white transition-colors hover:bg-primary/10'
            >
              <a
                href={link[locale as 'en'] || link.en}
                target='_blank'
                rel='noreferrer noopener'
                className='social_media_follow p-2'
                data-location='footer'
                data-category={link.name}
              >
                <Image width={17} height={17} src={link.icon} alt={link.name} className='w-6' />
              </a>
            </li>
          ))}
        </ul>
        <div className='flex flex-col items-center gap-2 lg:flex-row'>
          <p className='text-center text-sm'>{t('common:download_app')}</p>
          <AppsDownload />
        </div>
      </div> */}

      <div className='container flex items-center justify-end gap-4 border-t border-white/10 pt-8'>
        <Copyright />
      </div>
    </footer>
  );
};

export default MainFooter;

import WBKLogo from '@/components/wbk/Logo';
import { useTranslation } from 'next-i18next';

const Copyright = () => {
  const { t } = useTranslation();
  return (
    <div className='flex flex-wrap items-center justify-center gap-4'>
      <span className='text-center text-xs text-white'>
        {t('common:footer.rights', { year: new Date().getFullYear() })}
      </span>
      <div className='flex items-center gap-3'>
        <span className='text-xs text-white'>{t('common:footer.powered')}</span>
        <WBKLogo />
      </div>
    </div>
  );
};

export default Copyright;

import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';

type Props = {
  width?: number;
  height?: number;
  hoverable?: boolean;
};

const WBKLogo = ({ width = 58, height = 18, hoverable = true }: Props) => {
  const { locale } = useRouter();
  const lang = locale?.includes('ar') ? 'ar' : 'en';
  return (
    <Link
      href={`https://webook.com/${lang}/business?utm_source=powered_by&utm_medium=ring-of-fire`}
      target='_blank'
      rel='noreferrer'
    >
      <Image
        className={`transition ${hoverable ? 'hover:scale-110' : ''}`}
        width={width}
        height={height}
        src='/icons/wbk.svg'
        alt='webook.com'
      />
    </Link>
  );
};

export default WBKLogo;

import { useTranslation } from 'next-i18next';
import { DefaultSeo as Seo } from 'next-seo';
import { useRouter } from 'next/router';

const DefaultSeo = () => {
  const { t } = useTranslation();
  const { asPath, locale, locales } = useRouter();
  const isHome = asPath === '/';

  return (
    <Seo
      // defaultTitle={t('common:seo.title')}
      description={t('common:seo.description')}
      titleTemplate={isHome ? undefined : `%s | ${t('common:seo.title')}`}
      // canonical={s + asPath}
      dangerouslySetAllPagesToNoFollow={process.env.NEXT_PUBLIC_ENV !== 'production'}
      dangerouslySetAllPagesToNoIndex={process.env.NEXT_PUBLIC_ENV !== 'production'}
      twitter={{
        cardType: 'summary',
        site: '@webook',
      }}
      openGraph={{
        site_name: t('common:seo.title'),
        title: t('common:seo.title'),
        description: t('common:seo.description'),
        locale: locale,
        type: 'website',
        url: 'https://ring-of-fire.co' + asPath,
        images: [
          {
            url: 'https://ring-of-fire.co/images/default-cover.jpg',
            alt: t('common:seo.alt'),
          },
        ],
      }}
      languageAlternates={locales?.map((locale) => ({
        hrefLang: locale,
        href: `https://ring-of-fire.co/${locale}${asPath === '/' ? '' : asPath}`,
      }))}
    />
  );
};

export default DefaultSeo;

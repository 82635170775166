import { Fragment } from 'react';
import Footer from './footer';
import Header from './header';

const Layout = ({ children, header, footer, hideLayout }: LayoutProps) => {
  if (hideLayout) {
    return <main id='main'>{children}</main>;
  }

  return (
    <Fragment>
      <Header settings={header} />
      <main id='main'>{children}</main>
      <Footer settings={footer} />
    </Fragment>
  );
};

export default Layout;

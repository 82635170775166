import Link from 'next/link';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import LanguageSwitch from '@/components/common/LanguageSwitch';
import MainButton from '@/components/buttons/MainButton';

type Props = {
  settings: LayoutProps['header'];
};

const Header = ({ settings }: Props) => {
  const { t } = useTranslation();
  const { back, locale } = useRouter();

  if (settings?.type === 'mini') {
    return (
      <nav className='container grid grid-cols-3 items-center py-4'>
        <div>
          <MainButton
            shape='text'
            className='xl:text-bas flex items-center gap-1 py-1 text-xs text-white md:text-base lg:text-sm ltr:pl-1 rtl:pr-1'
            onClick={back}
            data-testid='mini-nav-back'
          >
            <Image
              className='ltr:rotate-180'
              src='/icons/common/arrow.svg'
              width={20}
              height={20}
              alt={t('common:back')}
            />
            <span>{t('common:back')}</span>
          </MainButton>
        </div>

        <div className='mx-auto'>
          <Link
            href='/'
            className='logo-image shrink-0 hover:opacity-80'
            data-location='header'
            role='menuitem'
          >
            <Image
              src='/icons/logo.svg'
              className='max-w-20 sm:max-w-none'
              width={124}
              height={41}
              alt={t('common:seo.title')}
            />
          </Link>
        </div>
        <div className='text-end [&>a]:text-xs [&>a]:xl:text-base [&>a]:ltr:lg:text-sm'>
          <LanguageSwitch />
        </div>
      </nav>
    );
  }

  return (
    <nav className='max-w-screen-3xl container grid grid-cols-3 items-start py-4 md:grid-cols-2'>
      <div className='flex flex-col items-start justify-start gap-4 md:flex-row md:gap-2'>
        <LanguageSwitch className='text-xs xl:text-base ltr:lg:text-sm' />
        <Image
          src='/images/vision-2030.svg'
          className='max-w-12 md:max-w-20'
          alt=''
          width={85}
          height={149}
        />
      </div>

      <ul
        className='flex grow items-center justify-center gap-4 text-sm md:hidden xl:gap-6'
        role='menu'
      >
        {!settings?.hideLogo && (
          <li role='presentation'>
            <Link
              href='/'
              className='logo-image shrink-0 hover:opacity-80'
              data-location='header'
              role='menuitem'
            >
              <Image
                src='/icons/logo.svg'
                className='max-w-24 sm:max-w-none'
                width={124}
                height={41}
                alt={t('common:seo.title')}
              />
            </Link>
          </li>
        )}
      </ul>

      <div className='flex flex-col-reverse items-end justify-end gap-4 md:flex-row md:items-start md:gap-2'>
        <Link href='/'>
          <Image
            src='/images/riyadh-season.svg'
            className='max-w-12 md:max-w-20'
            alt=''
            width={76}
            height={151}
          />
        </Link>

        <MainButton
          className='gap-2 rounded-full px-2.5 py-2.5 leading-none md:px-6 md:py-3'
          as='a'
          href={`https://webook.com/${locale}/events/ring-of-fire-tickets?utm_source=book_now&utm_medium=ring-of-fire`}
          target='_blank'
          rel='noreferrer'
        >
          <p className='text-[11px] uppercase md:text-sm ltr:font-lemon rtl:font-lama'>
            {t('common:book_now')}
          </p>
          <Image
            className='max-w-3 md:max-w-4 ltr:mb-0.5 rtl:-scale-x-100'
            src='/icons/common/external.svg'
            width={16}
            height={16}
            alt=''
          />
        </MainButton>
      </div>
    </nav>
  );
};

export default Header;

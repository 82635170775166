import { datadogLogs } from '@datadog/browser-logs';
import packageJson from './package.json';

const initDatadog = () => {
  const isDataDog = process.env.NEXT_PUBLIC_ERROR_TRACKING_SERVICE === 'datadog';

  if (isDataDog) {
    if (!process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN) {
      console.warn('Missing Datadog config. Skipping Datadog init.');
      return;
    }
    datadogLogs.init({
      clientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN,
      site: 'datadoghq.com',
      env: process.env.NEXT_PUBLIC_ENV,
      // Specify a version number to identify the deployed version of your application in Datadog
      version: packageJson.version,
      sessionSampleRate: process.env.NEXT_PUBLIC_ENV === 'production' ? 20 : 100,
      forwardErrorsToLogs: false,
      service: 'ring-of-fire-browser',
    });
  }
};

initDatadog();

import { loadScriptAsync } from '@/util/loadScriptAsync';
import { User } from '@/api/auth/types';
import { safeParse } from '@/util/json';

const CAN_RUN = process.env.NEXT_PUBLIC_ENV !== 'development';

const pushToDataLayer = (obj: Record<string, unknown>) => {
  if (typeof window !== 'undefined') {
    const dataLayer = window?.dataLayer;
    if (dataLayer) {
      dataLayer.push(obj);
      if (CAN_RUN) {
        console.log('Pushed to dataLayer:', obj);
      }
    }
  }
};

const usePixels = () => {
  // https://support.google.com/tagmanager/thread/260100414/sending-consent-data-to-gtm-via-gtag-and-datalayer?hl=en
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function gtag(...args: unknown[]) {
    // eslint-disable-next-line prefer-rest-params
    window.dataLayer.push(arguments);
  }

  const initGTMWithConsent = () => {
    if (!CAN_RUN) return;

    window.dataLayer = window.dataLayer || [];

    const consent = localStorage.getItem('cookie_consent');
    if (consent) {
      const parsed = safeParse<CookieConsent>(consent);
      setCookieConsent('default', parsed);
    } else {
      // Default denied all
      setCookieConsent('default', {
        analytics_storage: 'denied',
        ad_storage: 'denied',
        ad_user_data: 'denied',
        ad_personalization: 'denied',
      });
    }

    initGTM();
  };

  const setCookieConsent = (type: 'default' | 'update', data: CookieConsent) => {
    gtag('consent', type, data);
  };

  const initGTM = () => {
    if (!CAN_RUN) return;

    const GTM_IDS = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER?.split(',')?.filter((id) => id);
    if (!GTM_IDS) return;
    // Init GTM
    for (const id of GTM_IDS) {
      if (!id) continue;
      // Header GTM
      loadScriptAsync({
        id,
        target: 'head',
        snippet: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                  })(window,document,'script','dataLayer','${id}');`,
      });
      // Body Iframe
      loadScriptAsync({
        id: `${id}_noscript`,
        type: 'noscript',
        snippet: `<iframe src="https://www.googletagmanager.com/ns.html?id=${id}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
      });
    }
  };

  const initGtag = () => {
    if (!CAN_RUN) return;
    const GTAG_IDS = process.env.NEXT_PUBLIC_GOOGLE_GTAG?.split(',')?.filter((id) => id);
    if (!GTAG_IDS) return;
    for (const ID of GTAG_IDS) {
      loadScriptAsync({
        id: `${ID}_gtag`,
        url: `https://www.googletagmanager.com/gtag/js?id=${ID}`,
      });
      loadScriptAsync({
        id: `${ID}_gtag_script`,
        snippet: `window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', '${ID}')`,
      });
    }
  };

  const loginEvent = (method: string, user: User) => {
    pushToDataLayer({
      event: 'login',
      method,
      user: { ...user, access_token: '' },
    });
  };

  const signupEvent = (method: string, user: User) => {
    pushToDataLayer({
      event: 'signup',
      method,
      user: { ...user, access_token: '' },
    });
  };

  const setUserInDataLayer = (user: User) => {
    pushToDataLayer({
      user: { ...user, access_token: '' },
    });
  };

  const shareEvent = (method: string, id: string, contentType = '') => {
    pushToDataLayer({
      event: 'share',
      method,
      id,
      content_type: contentType,
    });
  };

  const customGA4Event = (eventName: string, data: Record<string, unknown>) => {
    pushToDataLayer({
      event: eventName,
      data,
    });
  };

  return {
    initGTMWithConsent,
    setCookieConsent,
    initGTM,
    initGtag,
    loginEvent,
    signupEvent,
    setUserInDataLayer,
    shareEvent,
    customGA4Event,
  };
};

export default usePixels;

import { Fragment, useMemo } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { createCookie } from '@/util/cookies';
import { Menu, Transition } from '@headlessui/react';
import MainButton from '@/components/buttons/MainButton';
import { twMerge } from 'tailwind-merge';

const LanguageSwitch = ({ className = '' }) => {
  const { locale, locales, asPath } = useRouter();
  const { t, i18n } = useTranslation();

  const handleDir = (goto: string) => {
    createCookie('lang', goto, 30);
    document.documentElement.dir = i18n.dir(goto);
  };

  const otherLangs = useMemo(() => {
    return (
      locales?.filter((l) => {
        return l !== locale && l !== 'default';
      }) || []
    );
  }, [locale, locales]);

  if (otherLangs?.length < 1) return null;

  if (otherLangs?.length > 1) {
    return (
      <Menu as='div' className='relative inline-block'>
        <div>
          <Menu.Button
            as={MainButton}
            shape='text'
            theme='secondary'
            className={`inline-flex h-8 w-full items-center gap-1 !px-2 !py-1 text-sm uppercase
            ${className || ''}`}
            data-testid='lang-switcher'
            data-location='header'
          >
            {otherLangs?.join(' / ')}
            <svg
              width='20'
              height='20'
              viewBox='0 0 24 24'
              className='fill-gray-95 rotate-180 transform transition-transform'
            >
              <path d='M6.70710678,15.7071081 C6.31658249,16.0976324 5.68341751,16.0976324 5.29289322,15.7071081 C4.90236893,15.3165838 4.90236893,14.6834188 5.29289322,14.2928945 L11.2928932,8.29289454 C11.6714722,7.9143156 12.2810586,7.90106998 12.6757246,8.26284718 L19.4313874,14.2942293 C19.8385064,14.6674217 19.8660094,15.2999891 19.4928169,15.7071081 C19.1196245,16.1142271 18.4870572,16.14173 18.0799382,15.7685376 L12.0300757,10.3841391 L6.70710678,15.7071081 Z'></path>
            </svg>
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter='transition ease-out duration-100'
          enterFrom='transform opacity-0 scale-95'
          enterTo='transform opacity-100 scale-100'
          leave='transition ease-in duration-75'
          leaveFrom='transform opacity-100 scale-100'
          leaveTo='transform opacity-0 scale-95'
        >
          <Menu.Items
            className='absolute left-1/2 z-30 w-[90px] origin-top -translate-x-1/2 divide-y divide-gray-100
            overflow-hidden rounded-lg border border-gray-100 bg-white shadow-lg focus:outline-none'
          >
            {otherLangs?.map((lang) => (
              <Menu.Item key={lang}>
                {({ active }) => (
                  <MainButton
                    as={Link}
                    href={asPath}
                    locale={lang}
                    shape='text'
                    theme='secondary'
                    rounded={false}
                    className={`w-full !ring-0
                  ${active ? 'bg-secondary-light/20' : 'bg-white'}`}
                    onClick={() => handleDir(lang)}
                    data-target-language={lang}
                  >
                    {t(`common:${lang}`)}
                  </MainButton>
                )}
              </Menu.Item>
            ))}
          </Menu.Items>
        </Transition>
      </Menu>
    );
  }

  return (
    <MainButton
      as={Link}
      href={encodeURI(asPath)}
      locale={otherLangs[0]}
      theme='white'
      shape='text'
      className={twMerge('py-1', className)}
      onClick={() => handleDir(otherLangs[0])}
      data-testid='lang-switcher'
      data-location='header'
      data-target-language={otherLangs[0]}
      role='menuitem'
    >
      {t(`common:${otherLangs[0]}`)}
    </MainButton>
  );
};

export default LanguageSwitch;

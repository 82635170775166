import { useEffect } from 'react';
import usePixels from '@/hooks/usePixels';
import { Trans, useTranslation } from 'next-i18next';
import toast from 'react-hot-toast';
import Link from 'next/link';
import MainButton from '../buttons/MainButton';

const CookieConsent = () => {
  const { t } = useTranslation();
  const { setCookieConsent } = usePixels();

  const saveConsent = (data: CookieConsent) => {
    localStorage.setItem('cookie_consent', JSON.stringify(data));
  };

  useEffect(() => {
    const consent = localStorage.getItem('cookie_consent');

    if (!consent) {
      // Show toaster cookie consent
      toast(
        (to) => (
          <div className='space-y-2 rounded-md bg-white text-text-dark'>
            <p className='text-sm text-gray-800'>
              <Trans i18nKey='cookie.description' ns='common'>
                <Link href='/terms' className='text-gray-600 underline'></Link>
              </Trans>
            </p>

            <div className='flex items-center justify-between gap-2'>
              <MainButton
                className='grow gap-2 ring-offset-white'
                onClick={() => {
                  toast.dismiss(to.id);
                  const data: CookieConsent = {
                    analytics_storage: 'granted',
                    ad_storage: 'granted',
                    ad_user_data: 'granted',
                    ad_personalization: 'granted',
                  };
                  saveConsent(data);
                  setCookieConsent('update', data);
                }}
              >
                <svg width={15} height={15} viewBox='0 0 512 512' fill='currentColor'>
                  <path d='M510.52 255.82c-69.97-.85-126.47-57.69-126.47-127.86-70.17 0-127-56.49-127.86-126.45-27.26-4.14-55.13.3-79.72 12.82l-69.13 35.22a132.221 132.221 0 0 0-57.79 57.81l-35.1 68.88a132.645 132.645 0 0 0-12.82 80.95l12.08 76.27a132.521 132.521 0 0 0 37.16 72.96l54.77 54.76a132.036 132.036 0 0 0 72.71 37.06l76.71 12.15c27.51 4.36 55.7-.11 80.53-12.76l69.13-35.21a132.273 132.273 0 0 0 57.79-57.81l35.1-68.88c12.56-24.64 17.01-52.58 12.91-79.91zM176 368c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32zm32-160c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32zm160 128c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32z' />
                </svg>
                {t('common:cookie.accept')}
              </MainButton>
              <MainButton
                shape='outlined'
                theme='transparent'
                className='bg-gray-100'
                onClick={() => {
                  toast.dismiss(to.id);
                  const data: CookieConsent = {
                    analytics_storage: 'denied',
                    ad_storage: 'denied',
                    ad_user_data: 'denied',
                    ad_personalization: 'denied',
                  };
                  saveConsent(data);
                  setCookieConsent('update', data);
                }}
              >
                {t('common:cookie.reject')}
              </MainButton>
            </div>
          </div>
        ),
        {
          duration: Infinity,
          position: 'bottom-left',
          id: 'cookie_consent',
        }
      );
    }
    // eslint-disable-next-line
  }, [t]);

  return <></>;
};

export default CookieConsent;
